import Swiper from 'swiper';
import {
	Navigation,
	Pagination,
	Autoplay,
	EffectCreative,
} from 'swiper/modules';

const MOBILE = '(max-width: 640px)';
const creativeEffects = {
	default: {
		prev: {
			translate: ['-500%', '-1.8%', 0],
			scale: 0,
		},
		next: {
			translate: ['500%', '-1.8%', 0],
			scale: 0,
		},
		limitProgress: 3,
		progressMultiplier: 0.206,
	},
	defaultMobile: {
		prev: {
			translate: ['-110%', '-1%', -135],
		},
		next: {
			translate: ['110%', '-1%', -135],
		},
		limitProgress: 2,
	},
	cases: {
		prev: {
			rotate: [0, 0, -10],
			translate: ['-162%', '-7%', 0],
			scale: 0.6,
		},
		next: {
			rotate: [0, 0, -10],
			translate: ['162%', '-7%', 0],
			scale: 0.6,
		},
		limitProgress: 3,
		progressMultiplier: 0.7,
	},
	casesMobile: {
		prev: {
			rotate: [0, 0, -7],
			translate: ['-130%', 0, 0],
		},
		next: {
			rotate: [0, 0, -7],
			translate: ['130%', 0, 0],
		},
		limitProgress: 2,
	},
};

class CollectionSlider {
	constructor(el) {
		this.init(el);
	}

	init(el) {
		this.container = el;

		if (!this.container) {
			return;
		}

		const isCases = this.container.classList.contains('_cases');
		this.effect = creativeEffects['default'];

		const mm = window.matchMedia(MOBILE);
		const onMMChange = (e) => {
			this.slider?.destroy();

			if (isCases) {
				this.effect = e.matches
					? creativeEffects['casesMobile']
					: creativeEffects['cases'];
			} else {
				this.effect = e.matches
					? creativeEffects['defaultMobile']
					: creativeEffects['default'];
			}

			this.initSlider();
		};

		mm.addEventListener('change', onMMChange);
		onMMChange(mm);
	}
	initSlider() {
		const sliderContainer = this.container.querySelector('.swiper');
		const nextButton = this.container.querySelector('[data-nav-button-next]');
		const prevButton = this.container.querySelector('[data-nav-button-prev]');
		const pagination = this.container.querySelector('.swiper-pagination');
		const autoplayDelay = this.container.getAttribute(
			'data-collection-slider-autoplay',
		);

		const modulesList = [Navigation, Pagination, EffectCreative];

		const autoplay = !!autoplayDelay && {
			delay: Number(autoplayDelay),
		};

		if (autoplay) {
			modulesList.push(Autoplay);
		}

		this.slider = new Swiper(sliderContainer, {
			modules: modulesList,
			slidesPerView: 'auto',
			centeredSlides: true,
			loop: true,
			grabCursor: true,
			speed: 600,
			autoplay: autoplay,
			pagination: {
				el: pagination,
			},
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton,
			},
			effect: 'creative',
			creativeEffect: this.effect,
			on: {
				autoplayTimeLeft: (swiper, timeLeft, progress) => {
					sliderContainer.style.setProperty(
						'--autoplay-progress',
						progress.toFixed(2),
					);
				},
				afterInit: () => {
					this.container.classList.add('_inited');
				},
			},
		});
	}
}

document
	.querySelectorAll('[data-collection-slider]')
	.forEach((el) => new CollectionSlider(el));

export default CollectionSlider;
