class BackPage {
	constructor() {
		this.init();
	}
	init() {
		const buttonsBack = document.querySelectorAll('[data-back-btn]');

		buttonsBack.forEach((button) => {
			button.addEventListener('click', () => {
				window.history.go(-1);
			});
		});
	}
}

export default new BackPage();
